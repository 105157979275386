import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { User } from "@app/core/models/user";
import { Router } from "@angular/router";
import { CoreUrlProvider } from "@app/core/config/core-url.provider";
import { ApiHttpService } from "@app/core/services/api-http.service";
import { ChangePassword } from "@app/shared/models/change-password";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(
    private _router: Router,
    private _http: ApiHttpService
  ) {
  }

  /***
   * Get current user
   */
  public getCurrentUser(): Observable<User> {
    return this._http.get<User>(CoreUrlProvider.URL.AUTH.USER_ME);
  }

  /***
   * Change password
   * @param data
   */
  public changePassword(data: ChangePassword): Observable<any> {
    return this._http.put<User>(CoreUrlProvider.URL.AUTH.USER_ME_PASSWORD, data);
  }

  /***
   * Logout user
   */
  public logout(): Observable<any> {
    return this._http.get<User>(CoreUrlProvider.URL.AUTH.LOGOUT);
  }

}
