import {Injectable, OnDestroy} from "@angular/core";
import {filter, Observable, Subject, take} from "rxjs";
import {Store} from "@app/core/utils/store";
import {User} from "@app/core/models/user";
import {AuthenticationService} from "@app/core/services/authentication.service";
import {Router} from "@angular/router";
import {UserData} from "@app/core/models/user-data";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {LoginResponse} from "angular-auth-oidc-client/lib/login/login-response";
import {UserFacade} from "@app/core/facades/user.facade";
import {ChangePassword} from "@app/shared/models/change-password";

@Injectable({
  providedIn: 'root'
})
export class AuthStore implements OnDestroy {
  public checkAuth$: Observable<LoginResponse> = this._oidcSecurityService.checkAuth();
  private _unsubscriber: Subject<void> = new Subject<void>()
  private _store = new Store<{
    loading: boolean
    changePasswordLoading: boolean
    error: any,
    user: User | null,
    isAuthenticated: boolean,
    accessToken: string | null
  }>(
    {
      loading: false,
      changePasswordLoading: false,
      error: null,
      user: null,
      isAuthenticated: false,
      accessToken: null
    },
    this._unsubscriber,
  );
  public user$: Observable<User | null> = this._store.select('user');
  public isLogging$: Observable<boolean> = this._store.select('loading');
  public isAuthenticated$: Observable<boolean> = this._store.select('isAuthenticated');
  public changePasswordLoading$: Observable<boolean> = this._store.select('changePasswordLoading');
  public error$: Observable<boolean> = this._store.select('error').pipe(filter(value => !!value));

  constructor(
    private _authenticationService: AuthenticationService,
    private _oidcSecurityService: OidcSecurityService,
    private _router: Router,
    private _userFacade: UserFacade,
  ) {
    if (this.state.user)  {
      this._userFacade.setUserActions(this.state.user?.permissions!);
    }
  }

  public get user(): User | null {
    return this._store.getState().user;
  }

  public get isLoggedIn(): boolean {
    return this._store.getState().isAuthenticated;
  }

  public get accessToken(): string | null {
    return this._store.getState().accessToken;
  }

  public get state(): any {
    return this._store.getState();
  }

  public ngOnDestroy(): void {
    this._unsubscriber.next()
    this._unsubscriber.complete()
  }

  public setUser(user: User|null) {
    this._store.setState({user: user})
  }

  public setUserData(data: UserData) {
    this._store.setState({isAuthenticated: data.isAuthenticated, accessToken: data.accessToken});
  }

  public getCurrentUser() {
    const state = this._store.getState();
    if (!state.isAuthenticated) {
      this.clearAuthStore();
      return;
    }
    this._store.setState({loading: true})
    this._authenticationService.getCurrentUser()
      .pipe(
        take(1),
      )
      .subscribe({
        next: (data: User) => {
          this._store.setState({
            user: data,
            loading: false,
            error: null
          });
          this._userFacade.setUserActions(data?.permissions!);
        },
        error: (error) => {
          this._store.setState({
            loading: false,
            error: error
          });
        }
      });
  }

  public login() {
    this._oidcSecurityService.authorize();
  }

  public logout() {
    this._oidcSecurityService.logoff()
      .pipe(take(1))
      .subscribe(() => {});
  }

  public clearAuthStore() {
    this._store.setState({
      loading: false,
      error: null,
      user: null,
      isAuthenticated: false,
      accessToken: null
    });
  }

  public changePassword(data: ChangePassword) {
    if (!data) {
      return;
    }
    this._store.setState({changePasswordLoading: true});
    this._authenticationService.changePassword(data)
      .pipe(
        take(1),
      )
      .subscribe({
        next: (data: any) => {
          this._store.setState({
            changePasswordLoading: false,
            user: {...this.state.user, passwordChangingRequired: false},
            error: null
          });
        },
        error: (error) => {
          this._store.setState({
            changePasswordLoading: false,
            error: error
          });
        }
      })
  }
}
