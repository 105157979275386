import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {User} from "@app/core/models/user";
import {AuthStore} from "@app/core/stores/auth.store";
import {UserData} from "@app/core/models/user-data";
import {LoginResponse} from "angular-auth-oidc-client/lib/login/login-response";
import {ChangePassword} from "@app/shared/models/change-password";

@Injectable({
  providedIn: 'root'
})
export class AuthFacade {

  /***
   * User data
   */
  public user$: Observable<User | null> = this._authStore.user$;
  /***
   * Is user logging
   */
  public isLogging$: Observable<boolean> = this._authStore.isLogging$;
  /***
   * error data
   */
  public error$: Observable<boolean> = this._authStore.error$;
  /***
   * Is user changing password
   */
  public changePasswordLoading$: Observable<boolean> = this._authStore.changePasswordLoading$;
  /***
   * Check if user is authenticated
   */
  public checkAuth$: Observable<LoginResponse> = this._authStore.checkAuth$;
  /***
   * Check if user is loggedIn
   */
  public isAuthenticated$: Observable<boolean> = this._authStore.isAuthenticated$;

  constructor(
    private _authStore: AuthStore,
  ) {
  }

  /***
   * Get user data
   */
  public get user(): User | null {
    return this._authStore.user;
  }

  /***
   * Check if user is logged in
   */
  public get isLoggedIn(): boolean {
    return this._authStore.isLoggedIn;
  }

  /***
   * Get access token
   */
  public get accessToken(): string | null {
    return this._authStore.accessToken;
  }

  /***
   * Run action to get current user
   */
  public getCurrentUser() {
    this._authStore.getCurrentUser();
  }

  /***
   * Run action to set user data
   * @param data
   */
  public setUserData(data: UserData) {
    this._authStore.setUserData(data)
  }


  /***
   * Run action to login
   */
  public login() {
    this._authStore.login();
  }

  /***
   * Run action to logout
   */
  public logout() {
    this._authStore.logout();
  }

  /***
   * Run action to change password
   * @param data
   */
  public changePassword(data: ChangePassword) {
    this._authStore.changePassword(data);
  }

}
